<template>
  <div>
  <div class="add-entrance" @click="createEntrance" v-if="!creatingEntrance">
    + Добавить подъезд
  </div>
  <div class="entrance-item" style="margin-top: 30px" v-if="creatingEntrance">
    <div class="create-block">
      <input type="text"
             class="newEntranceInput mr-5"
             placeholder="Введите название"
             v-model="newEntranceName"
             ref="newEntrance"
             @blur="hideCreatingEnrtance"
             @keyup.enter="stopCreatingEntrance(newEntranceName,block.id)">
      <div class="create-block-btn" @click="stopCreatingEntrance(newEntranceName,block.id)">
        <img src="../../assets/images/Active.svg" alt=""></div>
      <div class="cancel-block-btn" @click="hideCreatingEnrtance" >
        <img src="../../assets/images/cancel-btn.svg" alt="">
      </div>
<!--      <div class="dots-open">-->
<!--        <img src="@/assets/images/dots.svg" alt="" />-->
<!--      </div>-->
    </div>

<!--    <hr style="height: 2px;color: #F0F0F3;border-width: 0px;background-color:#F0F0F3;margin-top: 30px" >-->
  </div>
  </div>
</template>

<script>
export default {
  name: "AddEntrance",
  props:{
    block:{
      type:Object,
      default: () => {},
    },
  },
  data: () => ({
    creatingEntrance:false,
    newEntranceName:"",
  }),
  methods:{
    async createEntrance(){
      this.creatingEntrance = true;
      this.$nextTick(() => this.$refs.newEntrance.focus());
    },
    async stopCreatingEntrance(newEntranceName,block){
      console.log(newEntranceName,block);
      this.$emit('create',newEntranceName,block);
      this.hideCreatingEnrtance()
    },
    hideCreatingEnrtance(){
      setTimeout(()=>{
        this.newEntranceName='';
        this.creatingEntrance=false;
      },200);
    },
  }
}
</script>

<style scoped>

</style>